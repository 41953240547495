window.ShuupWishlist = {
  getCSRFToken: function () {
    var cookieValue = null;
    var name = "csrftoken";
    if (document.cookie && document.cookie !== "") {
      var cookies = document.cookie.split(";");
      var BreakException = {};

      try {
        cookies.forEach((c) => {
          var cookie = $.trim(c);
          if (cookie.substring(0, name.length + 1) === name + "=") {
            cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
            throw BreakException;
          }
        });
      } catch (error) {
        if (error !== BreakException) {
          console.error(error);
        }
      }
    }
    return cookieValue;
  },
  removeProduct(wishlistId, shopProductId, hideElement = null) {
    this.query("remove", wishlistId, shopProductId, hideElement);
  },
  addProduct(wishlistId, shopProductId) {
    this.query("add", wishlistId, shopProductId);
  },
  query(action, wishlistId, shopProductId, hideElement = null) {
    if (wishlistId === null) {
      wishlistId = "default";
    }
    var urlAction = action === "remove" ? "remove/?ajax=1" : "";
    var that = this;
    $.ajax({
      url: interpolate("/wishlist/%s/product/%s/%s", [wishlistId, shopProductId, urlAction]),
      method: "POST",
      data: {
        csrfmiddlewaretoken: this.getCSRFToken(),
      },
      success: function (response) {
        var msg = "";
        if (action === "add") {
          if (response.created) {
            msg = interpolate(gettext("%s has been added to your favorite psychics list."), [response.product_name]);
            flashMessage("success", msg);
          } else if (response.err) {
            flashMessage("danger", gettext("Error add item to list. Try again later."));
          } else {
            msg = interpolate(gettext("%s is already in list!"), [response.product_name]);
            flashMessage("danger", msg);
          }
        } else {
          if (response.removed) {
            flashMessage(
              "success",
              interpolate(gettext("%s has been removed from your favorite psychics list."), [response.product_name])
            );
            if (hideElement) {
              const slickIndex = hideElement.data("slick-index");
              if (slickIndex !== undefined) {
                // cloned items
                const grid = hideElement.parents(".slick-slider");
                const { slideCount, options } = grid.slick("getSlick");
                if (slideCount <= 1) {
                  options.dots = false;
                }
                const slideIndex = (slickIndex + slideCount) % slideCount;
                grid.slick("slickRemove", slideIndex).slick("unslick").slick(options);
              } else {
                hideElement.hide();
              }
            }
          } else {
            flashMessage("danger", gettext("Error removing item from list. Try again later."));
          }
        }
      },
      error: function (err) {
        if (err && err.responseJSON && err.responseJSON.err) {
          flashMessage("danger", err.responseJSON.err);
        }
      },
    });
  },
};

if (typeof window.$ === "function") {
  window.getProductIdToAdd = function getProductIdToAdd(el) {
    return $(el).attr("data-shop-product-id");
  };

  window.initializeWishlist = function () {
    $(".add-to-favorite")
      .off("click")
      .on("click", function (event) {
        event.preventDefault();

        let shopProductId = window.getProductIdToAdd(this);
        let wishlistId = $(this).data("wishlist-id");
        let remove = $(this).hasClass("is-active");
        if (shopProductId && wishlistId) {
          const copiesOfHearts = $(`[data-shop-product-id="${$(this).data("shop-product-id")}"]`);
          if (remove) {
            let rowToHide = $(this).data("can-remove") && $(this).parents(".global-component_psychic-block");
            window.ShuupWishlist.removeProduct(wishlistId, shopProductId, rowToHide);
            copiesOfHearts.removeClass("is-active");
            if ($(this).data("content")) {
              $(this).attr("data-content", gettext("Add to favorites"));
            }
          } else {
            window.ShuupWishlist.addProduct(wishlistId, shopProductId);
            copiesOfHearts.addClass("is-active");
            if ($(this).data("content")) {
              $(this).attr("data-content", gettext("Remove favorite"));
            }
          }
        }
      });
  };
  $(document).ready(function () {
    window.initializeWishlist();
  });
}
